// 변수 사용하기
$mainColor: #2a2b40;
$line: #dddddd;

@mixin square($size) {
  $calculated: 32px * $size;
  width: $calculated;
  height: $calculated;
}

@mixin center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin shadow() {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 2px 8px;
}
